<template>
  <!-- Container for the Three.js scene -->
  <div ref="threeContainer" style="width: 100%; height: 100vh; margin: 0; padding: 0"></div>
</template>

<script>
import * as THREE from 'three';

export default {
  name: 'CubeView',
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      orientationBeta: 0,
      orientationGamma: 0,
      velocity: null,
      sparkles: [],
      colors: [
        // Additional vibrant or subtle colors from your theme
        '#db8b45', // cc_second
        '#f7efd4', // cc_third
        '#edcda4', // cc_a
        '#e4ac74', // cc_b
      ],
    };
  },
  mounted() {
    // Grab container size
    const width = this.$refs.threeContainer.clientWidth;
    const height = this.$refs.threeContainer.clientHeight;

    const isMobile = width < 768;

    // Scene & background
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('#1f1e1e');

    // Camera
    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    this.camera.position.z = isMobile ? 3.5 : 4.5; // Slightly further back for desktop

    // Renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(width, height);
    this.$refs.threeContainer.appendChild(this.renderer.domElement);

    // Cube size
    this.cubeSize = isMobile ? 1 : 2;
    const cubeGeometry = new THREE.BoxGeometry(this.cubeSize, this.cubeSize, this.cubeSize);
    const cubeMaterial = new THREE.MeshBasicMaterial({ color: '#f7efd4', wireframe: true });
    this.cube = new THREE.Mesh(cubeGeometry, cubeMaterial);
    this.scene.add(this.cube);

    // Ball
    this.ballRadius = isMobile ? 0.1 : 0.2;
    const ballGeometry = new THREE.SphereGeometry(this.ballRadius, 32, 32);
    const ballMaterial = new THREE.MeshBasicMaterial({ color: '#db8b45' });
    this.ball = new THREE.Mesh(ballGeometry, ballMaterial);
    this.scene.add(this.ball);

    // Initial ball velocity
    this.velocity = new THREE.Vector3(0.01, 0.015, 0.02);

    // Mouse & orientation events
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('deviceorientation', this.handleDeviceOrientation, true);

    // Interaction: spawn sparkles on click/touch
    this.$refs.threeContainer.addEventListener('mousedown', this.handleSpawnSparkle);
    this.$refs.threeContainer.addEventListener('touchstart', this.handleSpawnSparkle);

    // Main animation loop
    const animate = (time) => {
      requestAnimationFrame(animate);

      // Cube spin
      this.cube.rotation.x = (time / 5000) * 2 * Math.PI;
      this.cube.rotation.y = (time / 5000) * 2 * Math.PI;

      // Extra rotation from mouse/orientation
      const extraRotX = (this.mouseY - height / 2) * 0.0005 + this.orientationBeta * 0.0005;
      const extraRotY = (this.mouseX - width / 2) * 0.0005 + this.orientationGamma * 0.0005;
      this.cube.rotation.x += extraRotX;
      this.cube.rotation.y += extraRotY;

      // Move ball
      this.ball.position.add(this.velocity);
      this.checkBallCollisions();

      // Update sparkles
      this.updateSparkles();

      this.renderer.render(this.scene, this.camera);
    };
    animate();

    // Resize
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeUnmount() {
    // Cleanup
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('deviceorientation', this.handleDeviceOrientation, true);
    if (this.$refs.threeContainer) {
      this.$refs.threeContainer.removeEventListener('mousedown', this.handleSpawnSparkle);
      this.$refs.threeContainer.removeEventListener('touchstart', this.handleSpawnSparkle);
    }
  },
  methods: {
    onWindowResize() {
      if (!this.$refs.threeContainer) return;
      const width = this.$refs.threeContainer.clientWidth;
      const height = this.$refs.threeContainer.clientHeight;
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    },

    handleMouseMove(e) {
      this.mouseX = e.clientX;
      this.mouseY = e.clientY;
    },

    handleDeviceOrientation(e) {
      this.orientationBeta = e.beta || 0;
      this.orientationGamma = e.gamma || 0;
    },

    checkBallCollisions() {
      const halfCube = this.cubeSize / 2;
      const minVal = -halfCube + this.ballRadius;
      const maxVal = halfCube - this.ballRadius;

      if (this.ball.position.x < minVal) {
        this.ball.position.x = minVal;
        this.velocity.x = -this.velocity.x;
      } else if (this.ball.position.x > maxVal) {
        this.ball.position.x = maxVal;
        this.velocity.x = -this.velocity.x;
      }

      if (this.ball.position.y < minVal) {
        this.ball.position.y = minVal;
        this.velocity.y = -this.velocity.y;
      } else if (this.ball.position.y > maxVal) {
        this.ball.position.y = maxVal;
        this.velocity.y = -this.velocity.y;
      }

      if (this.ball.position.z < minVal) {
        this.ball.position.z = minVal;
        this.velocity.z = -this.velocity.z;
      } else if (this.ball.position.z > maxVal) {
        this.ball.position.z = maxVal;
        this.velocity.z = -this.velocity.z;
      }
    },

    handleSpawnSparkle() {
      // pick random color
      const colorChoice = this.colors[Math.floor(Math.random() * this.colors.length)];
      // create a small sphere geometry
      const sparkleGeometry = new THREE.SphereGeometry(0.05, 8, 8);
      // material with transparency
      const sparkleMaterial = new THREE.MeshBasicMaterial({
        color: colorChoice,
        transparent: true,
        opacity: 1.0,
      });
      const sparkleMesh = new THREE.Mesh(sparkleGeometry, sparkleMaterial);

      // place near the ball
      sparkleMesh.position.copy(this.ball.position);
      sparkleMesh.position.x += (Math.random() - 0.5) * 0.3;
      sparkleMesh.position.y += (Math.random() - 0.5) * 0.3;
      sparkleMesh.position.z += (Math.random() - 0.5) * 0.3;

      // random velocity, a bit stronger so we see them float
      const sparkleVelocity = new THREE.Vector3(
        (Math.random() - 0.5) * 0.03,
        (Math.random() - 0.5) * 0.03,
        (Math.random() - 0.5) * 0.03
      );

      // store sparkle data
      const sparkleData = {
        mesh: sparkleMesh,
        velocity: sparkleVelocity,
        life: 5000, // ms, extended so they stay longer
        initialLife: 5000,
        radius: 0.05, // used for bounding collisions
      };

      this.sparkles.push(sparkleData);
      this.scene.add(sparkleMesh);
    },

    updateSparkles() {
      // update positions, keep them inside the cube, fade them out, remove if expired
      const delta = 16.7; // approximate ms/frame
      const halfCube = this.cubeSize / 2;

      for (let i = this.sparkles.length - 1; i >= 0; i--) {
        const s = this.sparkles[i];
        s.mesh.position.add(s.velocity);
        s.life -= delta;

        // bounce sparkles inside the same bounding as the ball (minus sparkle radius)
        const minVal = -halfCube + s.radius;
        const maxVal = halfCube - s.radius;

        // x collisions
        if (s.mesh.position.x < minVal) {
          s.mesh.position.x = minVal;
          s.velocity.x = -s.velocity.x;
        } else if (s.mesh.position.x > maxVal) {
          s.mesh.position.x = maxVal;
          s.velocity.x = -s.velocity.x;
        }
        // y collisions
        if (s.mesh.position.y < minVal) {
          s.mesh.position.y = minVal;
          s.velocity.y = -s.velocity.y;
        } else if (s.mesh.position.y > maxVal) {
          s.mesh.position.y = maxVal;
          s.velocity.y = -s.velocity.y;
        }
        // z collisions
        if (s.mesh.position.z < minVal) {
          s.mesh.position.z = minVal;
          s.velocity.z = -s.velocity.z;
        } else if (s.mesh.position.z > maxVal) {
          s.mesh.position.z = maxVal;
          s.velocity.z = -s.velocity.z;
        }

        // fade out linearly
        const ratio = s.life / s.initialLife;
        s.mesh.material.opacity = Math.max(ratio, 0);

        // remove if life expired
        if (s.life <= 0) {
          this.scene.remove(s.mesh);
          this.sparkles.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style scoped>
:root {
  /* color scheme custom properties */
  --cc_main: #1f1e1e;
  --cc_second: #db8b45;
  --cc_third: #f7efd4;
  --cc_a: #edcda4;
  --cc_b: #e4ac74;
  --cc_Form: #181717;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: var(--cc_main);
}
</style>
