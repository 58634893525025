import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CubeView from '../views/CubeView.vue'
import APIDataExtractorView from '../views/APIDataExtractorView.vue'
import MinigameView from '../views/MinigameView.vue'
import ContactView from '../views/ContactView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cube',
    name: 'cube',
    component: CubeView
  },
  {
    path: '/APIDataExtractor',
    name: 'APIDataExtractor',
    component: APIDataExtractorView
  },
  {
    path: '/minigame',
    name: 'minigame',
    component: MinigameView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
